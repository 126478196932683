import React, { useLayoutEffect, useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import logos from './../../assets/data/_logos'

import { Card, Section } from './style'

type logosProps = {
  imagem: string;
}

const ConhecaQuemSimplifica = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Section
      id='conheca-quem-simplifica'
      className='py-5 py-md-0 bg-white d-flex align-items-center'
    >
      <div className='container'>
        <div className='row d-flex justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 d-flex align-items-center'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>
              Conheça quem simplifica o recebimento de seus fornecedores com o Inter Empresas
            </h2>
          </div>
          <div className='col-12 col-md-6 pl-0'>
            {
              isMobile ? (
                <DefaultCarousel sm={{ items: 2, partialVisibilityGutter: 10 }} notShowDots notShowArrows>
                  {
                  logos.map((item: logosProps) => (
                    <div className='mx-2' key={item.imagem}>
                      <Card>
                        <img src={item.imagem} width={126} />
                      </Card>
                    </div>
                  ))
                }
                </DefaultCarousel>
              ) : (
                <div className='row'>
                  <div className='col-6 mt-md-n4 mb-md-n4 mb-lg-n3 mb-xl-n5 d-lg-flex flex-lg-column align-items-lg-end'>
                    {
                      logos.slice(0, 4).map((item: logosProps) => (
                        <Card key={item.imagem}>
                          <img src={item.imagem} />
                        </Card>
                      ))
                    }
                  </div>
                  <div className='col-6 d-md-flex justify-content-md-center flex-md-column'>
                    {
                    logos.slice(-3).map((item: logosProps) => (
                      <Card key={item.imagem}>
                        <img src={item.imagem} />
                      </Card>
                    ))
                  }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConhecaQuemSimplifica
