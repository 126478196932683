import { green, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`

  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }
`

export const styles = css`
  background: ${green[500]};
  border-radius: 8px;
  border: 1px solid #80BCBA;
  color: ${white};
  font-size: 14px;
  height: 48px;
  font-weight: 700;
  width: 100%;

  &:hover {
    opacity: 0.8;
    color: ${white};
  }
`

export const Button = styled.button`
  ${styles}

  &.bg-white {
    color: ${green[500]};
  }
`

export const LinkButton = styled.a`
  ${styles}
  align-items: center;
  justify-content: center;
`
